import React, { useState } from "react"
import { Box, Grid, Divider, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import dpcgclogo from "../images/dpcgc/dpcgclogo.svg"
import newicon from "../images/dpcgc/newicon.svg"
import { Link } from "gatsby"
import leader1 from "../images/leader1.svg"
import leader2 from "../images/leader2.svg"
import leader3 from "../images/leader3.svg"
import leader4 from "../images/leader4.svg"
import leader5 from "../images/leader5.svg"
import leader6 from "../images/grbmember6.jpg"

import Footer from "../components/dpcgc/Footer"
const useStyles = makeStyles(theme => ({
  rooot: {
    width: "100%",
    background: "#243974",
    // height: "22rem",
    padding: "8.5rem 0 2rem 0",
  },
  dpcgclogo: {
    height: "11rem",
  },
  iamailogo: {
    height: "4rem",
    margin: "2rem",
  },
  nav: {
    color: "#243974",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  grid: {
    margin: "10px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 10px",
    },
  },
  othernav: {
    color: "#4F4F4F",
    fontSize: "14px",
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  bigheading: {
    // width:"150px",
    // height:"30px",
    FontFace: "Roboto",
    fontSize: "26px",
    lineHeight: "30px",
    color: "#243974",
    marginleft: "334px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
  },
  member: {
    height: "15rem",
    marginTop: "25px",
  },
  text: {
    color: "#000000",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "justify",

    // [theme.breakpoints.down("xs")]: {
    //   padding: "0 29px",
    //   fontSize: "18px",
    //   lineHeight: "20px",
    // },
    [theme.breakpoints.down("md")]: {
      padding: "0 21px",
      fontSize: "14px",
      lineHeight: "22px",
      paddingTop: "2px",
      paddingRight: "0",
      // paddingRight:"20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0",
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      fontSize: "14px",
      lineHeight: "22px",
      paddingTop: "2px",
      paddingRight: "0",

      // paddingRight:"20px",
    },
  },
  text1: {
    color: "#4F4F4F",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    textAlign: "justify",

    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 29px",
      fontSize: "14px",
      lineHeight: "19px",
    },
    // [theme.breakpoints.down("xs")]: {
    //   padding: "0 29px",
    //   fontSize: "18px",
    //   lineHeight: "20px",
    // },
    [theme.breakpoints.down("md")]: {
      padding: "0 21px",
      fontSize: "14px",
      lineHeight: "19px",
      // paddingTop:"2px",
      // paddingRight:"20px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      fontSize: "14px",
      lineHeight: "19px",
      // paddingTop:"2px",
      // paddingRight:"20px",
    },
  },
  t: {
    color: "#4F4F4F",
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: "italic",
    fontFamily: "Roboto",
    fontWeight: "600",
    textAlign: "justify",
  },
  leader: {
    height:'22rem',
    [theme.breakpoints.down("sm")]: {
      height: "30rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30rem",
      //   paddingRight:"10px",
    },
    [theme.breakpoints.down("md")]: {
      height: "22rem",
    },
  },
  newicon: {
    height: "16px",
    [theme.breakpoints.down("md")]: {
      height: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "10px",
    },
  },
}))

export default function GRBMembers() {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.rooot}>
        <Grid container justifyContent="center">
          <Grid item>
            <Link to="/">
              <img
                src={dpcgclogo}
                alt="dpcgc logo"
                className={classes.dpcgclogo}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0} justifyContent="center">
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/whoweare" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>ABOUT US</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/grbmembers" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>GRB MEMBERS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/govtnotification" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              GOVT. NOTIFICATIONS
            </Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/discolsures" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>DISCLOSURES</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/faqs" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>FAQS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/leadersspeak" style={{ textDecoration: "none" }}>
            <Typography className={classes.nav}>LEADERSHIP TEAM</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          {/* <Box style={{ textAlign: "right" }}>
          <img src={newicon} alt="new" className={classes.newicon} />
          </Box> */}
          <Link to="/consumerresources" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              CONSUMER RESOURCES
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Box height="10rem" />
      <Box className={classes.root}>
        <Grid container justifyContent="center">
          <Grid item sm={8} md={7}>
            <Typography className={classes.bigheading}>
              Leadership Team
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item sm={3} md={2}>
            <Box height="8rem" />
            <img src={leader3} className={classes.leader} />
          </Grid>
          <Grid item sm={5} md={5} xs={10}>
            <Box height="8rem" />
            <Typography className={classes.text1}>
              Subho Ray, President, Internet and Mobile Association of India
            </Typography>
            <Box height="1rem" />
            <Typography className={classes.text}>
              He is responsible for conceptualising and implementing IAMAI’s
              strategy and activities around regulatory affairs, public policy,
              advocacy industry standards, research, communications and
              promotional events.
              <Box height="2rem" />
              Before joining IAMAI, Subho was Director for the ICT vertical at
              the Confederation of Indian Industry in New Delhi, where he worked
              since 1998, closely with the software &amp; services, BPM,
              hardware and telecom industries. In addition to being active in
              the areas of business development, marketing and communications,
              he is also deeply interested in public policy and regulatory
              affairs for technology. He is currently focused on understanding
              the impact of and policies for emerging technologies such as
              Artificial Intelligence, Blockchain, Augmented &amp; Virtual
              Reality, Mobility [driverless cars, drones, hyperloop].
              <Box height="2rem" />
              Subho is a trained historian having read history at Presidency
              College [Kolkata]; Jawahar Lal Nehru University [New Delhi] and
              the School of Oriental and African Studies [University of London].
              He is the author of "Transformations on the Bengal Frontier –
              1748-1948" [Routledge Curzon, 2002] and has co-authored, “Building
              India with Partnerships: The Story of CII” [Penguin India 2007].
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item sm={3} md={2}>
            <Box height="8rem" />
            <img src={leader1} className={classes.leader} />
          </Grid>
          <Grid item sm={5} md={5} xs={10}>
            <Box height="8rem" />
            <Typography className={classes.text1}>
              Karan Bedi, DPCGC Chairperson, MX Player CEO
            </Typography>
            <Box height="1rem" />
            <Typography className={classes.text}>
              Karan Bedi is a visionary and disruptor in the entertainment
              industry in India. Karan is a multifaceted and as CEO of MX Media,
              has driven MX to the No. 1 position in India within 18 months of
              its launch
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              He is a specialist in consumer-centric technology led businesses
              spanning across-- OTT, Ed-Tech, Digital Media Publishing, Mobile
              E-Commerce amongst others and has used the intersection of media
              and technology to create immersive user experiences. Karan's
              unique insight into content strategy and trends has also helped MX
              create a highly differentiated offering and propelled it to a
              leadership position in India.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              Karan brings in his entrepreneurial spirit to empower teams and
              strive for greater success as a leader. Before MX, Karan set up
              Eros Now and led it to become the largest Indian-diaspora focused
              OTT platform. Karan is an alumnus of the prestigious Stanford
              University, graduating with a BA in Economics in 2007.
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item sm={3} md={2}>
            <Box height="8rem" />
            <img src={leader2} className={classes.leader} />
          </Grid>
          <Grid item sm={5} md={5} xs={10}>
            <Box height="8rem" />
            <Typography className={classes.text1}>
              Ambika Khurana - DPCGC Co-Chairperson, Netflix Public Policy
              Director
            </Typography>
            <Box height="1rem" />
            <Typography className={classes.text}>
              Ambika Khurana is a Public Policy professional with almost 20
              years of leadership experience in Government Affairs and Strategic
              Planning. Her specialization includes advocacy with governments on
              policy and regulatory issues critical to the industry's goals and
              to Netflix's business agenda. She aligns with Government
              stakeholders for Technology, Media, Economic, and Market access
              policies- to strategize opportunities and mitigate business risks
              for the organization. She has extensively promoted ICT Policy
              agenda with the World Bank, UNESCO, USAID, DFID, and Ministries of
              IT of various international and national entities.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              Ambika holds an Engineering Degree from Thapar University,
              Patiala, and a Masters Degree in Management from IIT, Delhi. Prior
              to Netflix, her last held position was of Head-Public Policy, IBM.
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item sm={3} md={2}>
            <Box height="8rem" />
            <img src={leader6} className={classes.leader} />
          </Grid>
          <Grid item sm={5} md={5} xs={10}>
            <Box height="8rem" />
            <Typography className={classes.text1}>
              Sonali Dhir, Director - DPCGC
            </Typography>
            <Box height="1rem" />
            <Typography className={classes.text}>
              Sonali is an alumnus of ILS Law College, Pune University and has
              over 10 years of profound experience and diverse capabilities in
              handling complex and multi-jurisdictional issues pertaining to
              civil and commercial disputes, IT Rules, Arbitration and
              Intellectual Property Rights. Sonali has worked across industries
              and has advised Indian and International clients on various
              corporate, legal and regulatory issues. Sonali has extensively
              advised clients on various disputes pertaining to commercial
              litigation, intermediary liability, infringement and passing off
              trademarks and arbitral disputes. She has also advised clients on
              other legal issues in the IT industry pertaining to employees,
              data protection and privacy.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              Sonali is well versed in internet and digital technologies, the
              policies that surround them, and the complex legal and regulatory
              issues arising from them. She has responded to policy
              consultations on data governance, intermediary liability, takedown
              of online content and its application to online platforms.
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="center">
          <Grid item sm={3} md={2} >
            <Box height="8rem" />
            <img src={leader4} className={classes.leader} />
          </Grid>
          <Grid item sm={5} md={5} xs={10}>
            <Box height="8rem" />
            <Typography className={classes.text}>
              “A system built around a shared belief that consumer empowerment
              and creative excellence are key to the long-term success of the
              Indian entertainment industry will help Online Curated Content
              Providers to be at the forefront of taking the best stories from
              India to the world and bringing the finest stories from around the
              world to Indian consumers.”
            </Typography>
            <Box height="3rem" />
            <Typography className={classes.text1}>
              Bhanupreet Singh Saini, Head of Public Policy, IAMAI
            </Typography>
          </Grid>
        </Grid> */}
      </Box>
      <Box height="10rem" />
      <Footer />
    </>
  )
}
